<template>
  <div></div>
</template>

<script>
export default {
  name: "public",
  created() {
    this.$router.replace({
      path: this.$route.query.url,
      query: this.$route.query
    });
  }
};
</script>
